.form-container {
  max-width: 30em;
  display: grid;
  place-content: center;
  margin: auto;
  margin-top: 10em;
  color: white;
  padding: 1em;
  border: 1px var(--border-color);
  p {
    margin-top: 1em;
    color: white;
    text-align: center;
  }
  .register-form {
    .form-title {
      p {
        font-weight: 700;
      }
    }
    .form-group {
      display: flex;
      align-items: center;
      margin-top: 2em;
      border-bottom: 2px solid rgb(176, 170, 170);

      input {
        vertical-align: middle;
        width: 100%;
        padding: 2em 2em 1.1em 2em;
        font-size: 1rem;
        outline: none;
        border: none;
        background-color: inherit;
        color: white;
        border-radius: 10px;
      }

      .form-icon {
        color: white;
        font-size: 1.3em;
      }
    }

    button {
      margin-top: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100%;
      height: 3vh;
      font-size: 1.5rem;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

@media (max-width: 450px) {
  .form-container {
    margin-top: 1em;
    max-width: 20em;

    p{
      margin-top: 0em;
    }

    .register-form {
      max-width: 20em;
      .form-group {
        min-width: none;
        min-width: 5em;
        margin-top: 1em;
        
        input {
        }
      }
    }
    button {
      border: 1px solid red;
      min-width: 100%;
      font-size: 1em;
    }
  }
}
