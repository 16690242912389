.single-post-page {
  width: min(100%, 70em);
  // border: 1px solid red;
  margin: auto;
  display: flex;
  justify-content: center;

  p {
    margin: auto;
    color: white;
  }
  button {
    border: none;
  }

  .center-post-page {
    background-color: var(--homepage-bg);
    border-left: 1px var(--border-color);
    border-right: 1px var(--border-color);
    margin-right: 0.7em;
    padding-bottom: 1em;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    max-width: 48.3em;
    flex: 1;
    // overflow-y: auto;
    max-height: 93vh;
    scrollbar-width: none;
    -webkit-scrollbar: none;
    -webkit-appearance: none;

    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }

    .user-post-container {
      // margin-top: 1em;
      // overflow-y: auto;
      scrollbar-width: none;
      -webkit-scrollbar: none;
      -webkit-appearance: none;
      // border: 1px solid red;

      &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
      }
      min-width: 45em;
      padding: 1em;
      border-bottom: 3px var(--border-color);
      .user-post-info {
        display: inline-flex;

        img {
          max-width: 2em;
          border-radius: 50%;
          margin-right: 0.3em;
        }
        p {
          color: var(--user-post-color);
          text-align: center;
          font-size: 1.2rem;
        }
      }

      .user-post-content {
        margin-top: 0.5em;
        h {
          color: rgb(234, 231, 231);
          font-weight: 600;
          font-size: 1.5rem;
        }
        p {
          margin-top: 1.5em;
          // margin-left: 1em;
          font-size: 1.1rem;
          font-weight: 400;
        }

        .post-images-container {
          .post-image {
            max-width: 60%;
            max-height: 20%;
          }
        }

        .post-links-container {
          a {
            font-size: 1rem;
            color: rgb(102, 102, 193);
          }
        }
      }

      .post-likes-comments {
        display: flex;
        // max-width: 12em;

        .likes-comments-item {
          border-top: 1px var(--border-color);
          max-width: 10em;
          display: flex;
          font-size: 0.8em;
          margin-right: 1em;
          align-items: center;
          margin-top: 1rem;

          p {
            padding: 0.2em;
            display: flex;
            align-items: center;
            color: rgb(181, 173, 173);
          }
        }

        .post-icons {
          margin-right: 5px;
          font-size: 1.4em;

          &:hover {
            font-weight: 700;
            transition: 0.5s;
            transform: scale(1.3, 1.3);
            color: var(--icon-color);
            fill: var(--icon-color);
          }
        }
      }

      .add-comment {
        display: flex;
        flex-direction: column;
        max-width: 30em;
        margin-top: 3em;

        textarea {
          background-color: inherit;
          border: 1px solid rgb(169, 165, 165);
          outline: none;
          color: white;
          font-size: 1rem;
          min-height: 10vh;
          padding: 1em;
          margin-bottom: 2%;
          resize: none;
        }

        // button for post reply
        button {
          background-color: var(--icon-color);
          // border: 1px solid var(--user-post-color);
          font-weight: 700;
          font-size: 0.7em;
          max-width: 10em;
          min-height: 3vh;
          padding: 0.4em;
          border-radius: 5px;
          color: rgb(209, 206, 206);
        }
      }
    }

    .post-comments-container {
      display: grid;
      place-items: left;
      // max-width: 45em;
      // border: 1px solid red;
      margin-top: 1em;
      // margin-bottom: 10%;
      
      .all-comments {
        margin-top: 1em;
        color: white;

        scrollbar-width: none;
        -webkit-scrollbar: none;
        -webkit-appearance: none;

        &::-webkit-scrollbar {
          width: 0;
          background-color: transparent;
        }
        .user-comment-container {
          // background-color: rgb(49, 48, 48);
          display: flex;
          padding: 1em;
          border-radius: 15px;

          p,
          button {
            margin-left: 0.5em;
          }

          .user-comment {
            // border: 1px solid red;
            // display: flex;
            .comment-user-info {
              display: flex;
              align-items: center;
              img {
                max-width: 4%;
                border-radius: 50%;
              }
              p {
                margin-right: 0;
                font-weight: 600;
                font-size: 1rem;
                color: var(--comment-username-color);
              }
              span {
                margin-left: 0.5em;
                font-size: 0.8rem;
              }
            }

            // button for comment reply
            button {
              max-width: 10em;
              padding: 0.2em 1em 0.2em 1em;
              background-color: inherit;
              border: 1px solid var(--comment-username-color);
              color: rgb(221, 218, 218);
              margin-left: calc(4% + 0.5em);
            }

            .add-comment {
              display: flex;
              flex-direction: column;
              max-width: 20em;
              margin-top: 1em;
              margin-left: 2em;

              textarea {
                background-color: inherit;
                border: 1px solid rgb(169, 165, 165);
                outline: none;
                color: white;
                font-size: 1rem;
                min-height: 10vh;
                padding: 1em;
                margin-bottom: 2%;
                resize: none;
              }

              // button for replying to comment
              button {
                background-color: var(--icon-color);
                // border: 1px solid var(--user-post-color);
                font-weight: 700;
                font-size: 0.7em;
                max-width: 8em;
                min-height: 3vh;
                padding: 0.1em;
                border-radius: 5px;
                color: rgb(209, 206, 206);
                margin-left: 0;
              }
            }
            .comment-user-text {
              margin-bottom: 0.5em;
              font-size: 1rem;
              margin-left: calc(4% + 1em);
            }
            // button for comment reply
            // button{
            //   margin-left: calc(4% + 0.5em);
            // }
          }
        }
      }
    }
  }

  .right-post-page {
    background-color: var(--homepage-bg);
    min-width: 10.5em;
    max-width: 10.5em;
    padding: 1em;
    height: 93vh;
    border-left: 1px var(--border-color);
    border-right: 1px var(--border-color);
    button {
      border: none;
      color: white;
      width: 100%;
      height: 3vh;
      background-color: var(--icon-color);
      transition: 1s;
      &:hover {
        transition: 1s;
        background-color: rgb(57, 54, 54);
      }
    }
  }
}

@media (max-width: 450px) {
  .single-post-page {
    max-width: 100vw;
    height: 100vh;
    display: block;
    // border: 1px solid red;
    background-color: var(--homepage-bg);
    .left-post-page {
      display: none;
    }
    .right-post-page {
      display: none;
    }

    .user-post-content{
      max-width: 19em;

      h{
        font-size: 1.2rem !important;
      }

      p {
        font-size: 1rem !important;
      }

    }

    .center-post-page {
      margin-right: 0em;
      display: block;

      .add-comment {
        max-width: 15em !important;

        button {
          min-height: 4vh !important;
        }
      }
    }
  }
}

@media (min-width: 451px) and (max-width: 1200px) {
  .single-post-page {
    max-width: 100vw;
    height: 100vh;
    .left-post-page {
      display: none;
    }
    .user-post-content{
      max-width: 19em;
    }

    .center-post-page {
      display: block;
      
      .add-comment {
        max-width: 15em !important;

        button {
          min-height: 4vh !important;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .single-post-page {
    max-width: 100vw;
    height: 100vh;
    // border: 1px solid red;


    .center-post-page {
      display: block;

      .add-comment {
        max-width: 15em !important;

        button {
          min-height: 4vh !important;
        }
      }
    }
  }
}

