.make-post-button-container {
  button {
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    min-width: 100%;
    height: 2em;
    border-radius: 5px;
    background: var(--gradient-background);
    transition: 500ms;
    &:hover {
      box-shadow: 0px 5em 5em -5em rgba(250, 249, 249, 0.2);
      transform: scale(1.1, 1.1);
      // background: var(--gradient-background-hover);
    }
  }
}
