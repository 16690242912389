.right-side-page {
  background-color: var(--homepage-bg);
  min-width: 10.5em;
  max-width: 10.5em;
  height: 93vh;
  // height: 150vh;
  padding: 1em;
  border-left: 1px var(--border-color);
  border-right: 1px var(--border-color);

  .make-samplepost-button-container {
    p {
      text-align: center;
      margin: 1em 0em 1em 0em;
      font-weight: 600;
    }
    button {
      border: none;
      color: white;
      font-size: 1rem;
      font-weight: 600;
      min-width: 100%;
      height: 2em;
      border-radius: 5px;
      background: linear-gradient(135deg, #799217 0%, #46860f 100%);
      transition: 500ms;
      &:hover {
        border-radius: 5px;
        box-shadow: 0px 5em 5em -5em rgba(250, 249, 249, 0.2);
        transform: scale(1.1, 1.1);
        // background: var(--gradient-background-hover);
      }
    }
  }

  .right-homepage-about {
    p {
      margin-top: 1em;
      font-size: 1rem;
      padding: 1em 0em 1em 0em;
      border-bottom: 1px var(--border-color);
    }
  }
}
