.form-container-login {
  max-width: 30em;
  display: grid;
  place-content: center;
  margin: auto;
  margin-top: 10em;
  color: white;
  padding: 1em;
  border: 1px var(--border-color);
  border-radius: 5px;

  p {
    margin-top: 1em;
    color: white;
    text-align: center;
  }

  .login-form {
    .form-title {
      p {
        font-size: 1em;
        font-weight: 600;
      }
    }
    .form-group {
      display: flex;
      align-items: center;
      font-size: 1.5em;

      input {
        vertical-align: middle;
        width: 100%;
        padding: 1em 1em 0em 1em;
        margin: 10%;
        font-size: 1.2rem;
        outline: none;
        border: none;
        background-color: inherit;
        color: white;
      }
      border-bottom: 2px solid rgb(176, 170, 170);

      .form-icon {
        color: white;
        font-size: 1.2em;
      }
    }
    .form-button {
      button {
        min-width: 100%;
        margin-top: 10%;
        min-height: 3vh;
        font-size: 1.2rem;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

@media (max-width: 450px){

  .form-container-login{
    margin-top: 3em;
    .login-form{
      .form-title {
        p {
          font-size: 1em;
          font-weight: 600;
        }
      }
    }
  }
}