nav {
  position: relative;
  z-index: 2;
  background-color: var(--navbar-bg);
}

.main-nav-container {
  // width: 100vw;
  min-height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;

  .hamburgermenu-container {
    display: none;
  }
  .hamburgermenu-icon {
    display: none;
  }
  .nav-logo-items-toggle-mobile, .nav-logo-items-toggle {
    display: none;
  }

  .nav-logo-items {
    // width: min(100%, 70em);
    width: min(70em, 70em);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-link-right {
      min-width: 10.5em;
      max-width: 10.5em;
      display: flex;
      justify-content: center;
      justify-content: space-around;
      // align-items: center;
      border-left: 1px var(--border-color);
      border-right: 1px var(--border-color);

      .login,
      .signup,
      .profile,
      .logout {
        border-radius: 4px;
        text-align: center;
        max-width: 5.5em;
        min-width: 5.5em;
        font-size: 0.95rem;
        font-weight: 600;
        border: 0;
        margin: 0.5em;
        background-image: var(--gradient-background);
        transition: 500ms;
        &:hover {
          transform: scale(1.15, 1.15);
          box-shadow: 0 0.5em 0.5em -0.4em rgb(101, 229, 229);
          background-image: var(--gradient-background-hover);
        }
      }
    }
    .nav-link-center {
      // border: 1px solid red;
      min-width: 48.3em;
      max-width: 48.3em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      text-decoration: none;
    }
    .navbar-brand {
      text-align: center;
      border-left: 1px var(--border-color);
      border-right: 1px var(--border-color);
      text-decoration: none;
      min-width: 10.5em;
      max-width: 10.5em;
      padding: 0.5rem;
    }

    .nav-link {
      text-decoration: none;
      padding: 0.3rem;
      color: white;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

.banner {
  width: 100vw;
  height: 20vh;
  // border: 1px solid yellow;
  background-image: url("../../assets/background-pictures/banner.jpg");
  background-size: cover;
  background-position: 35% 43%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 30em) {
  .main-nav-container {
    // min-height: 20vh;
    // .hamburgeremu-container{
    //   display: block;
    //   border: 1px solid red;
    //   // margin-right: 10%;
    //   width: 10em;
    //   height:5vh;
    // }
    display: block;
    position: relative;
    
    // min-height: 20vh;
    .hamburgermenu-container {
      min-width: 2em;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      display: flex;
      justify-content: flex-end;
      
      .hamburgermenu-icon {
        font-size: 1.5em;
        display: block;
        color: white;
      }
    }
    
    .nav-logo-items-toggle-mobile {
      min-height: 90vh;
      border-top: 1px var(--border-color);
      padding: 1em;
      line-height: 1.5em;
      display: grid;
      place-content: center;

        .logout{
          background-color: var(--navbar-bg);
          border: none;
        }  
      
      .nav-link {
        min-width: 100vw;
        max-width: 100vw;
        padding: 0.5em;
        text-align: center;
        color: white;
        border-bottom: 1px solid rgb(45, 42, 42);
        display: block;
        text-decoration: none;
        font-size: 0.8em;
      }
    }

    .nav-logo-items {
      display: block;
      justify-content: right;
      align-items: right;

      .nav-link {
        display: none;
      }
      .navbar-brand {
        border: 0px;
        margin-left: 0em;
        display: block;
        margin: auto;
        padding: 0.5em;
      }
      .nav-link-right {
        display: block;
        min-width: 0em;
        max-width: 5em;
      }
    }
  }
}

@media (min-width: 451px) and (max-width: 1200px) {
  .main-nav-container {
   .navbar-home{
    display: none;
   }
   .navbar-brand {
    margin-left: 0em;
    display: block;
    margin: auto;
    padding: 0.5em;
  }
  }
}

