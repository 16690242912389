.main-page {
  width: min(100%, 70em);
  margin: auto;
  display: flex;
  // justify-content: center;
  p {
    margin: auto;
    color: white;
  }
  button {
    border: none;
  }

  .center-homepage {
    background-color: var(--homepage-bg);
    border-left: 1px var(--border-color);
    border-right: 1px var(--border-color);
    margin-right: 0.7em;
    // margin-left: 0.5em;
    // display: grid;
    place-content: top;
    // min-width: 48.3em;
    max-width: 48.3em;
    flex: 1;
    overflow-y: scroll;
    max-height: 93vh;
    min-height: 93vh;
    scrollbar-width: none;
    -webkit-scrollbar: none;
    -webkit-appearance: none;

    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }

    .loading-skeleton{
      display: flex;
      color: white;
    }

    .homepage-top-menu {
      display: flex;
      min-width: 48.3em;
      min-height: 3rem;
      max-height: 3rem;
      overflow-y: auto;
      border-bottom: 1px var(--border-color);

      .menu-button {
        margin-left: 1em;
        font-size: 0.9em;
        color: rgb(231, 226, 226);
        font-weight: 600;
        letter-spacing: -1px;
        &:hover {
          border-bottom: 3px solid rgb(206, 199, 199);
        }

        .homepage-top-menu-icons {
          margin-right: 0.2em;
        }
      }
      .menu-button.active {
        border-bottom: 3px solid rgb(206, 199, 199);
      }
      button {
        border: none;
        min-width: 15%;
        background-color: inherit;
      }
    }

    .homepage-bottom-page {
      .single-post {
        display: flex;
        margin-top: 1%;
        max-width: 100%;
        border-bottom: 1px var(--border-color);
        &:hover {
          box-shadow: 0 4px 6px rgba(183, 178, 178, 0.1);
        }

        .post-info {
          padding: 1em 1em 1em 2em;

          .post-username {
            font-size: 0.7em;
            color: rgb(101, 101, 174);
            font-weight: 900;
          }

          .post-title {
            font-size: 0.9em;
            font-weight: 900;
            text-shadow: 1px 1px 1px black;
          }
          .post-description {
            font-size: 0.7em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        img {
          min-width: 40%;
        }

        .post-likes-comments {
          margin-top: 0.6em;
          max-width: 5em;
          min-width: 5em;
          // border: 1px solid red;
          display: flex;
          .post-icons {
            margin: 0.5em 0em 0 0;
            color: white;
            font-size: 1em;
          }
          p {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .main-page {
    max-width: 100vw;
    // height: 100vh;
    display: block;
    // overflow-x: hidden;
    .center-homepage {
      min-width: 0px;
      margin-right: 0em;
      .homepage-top-menu {
        // border: 1px solid red;
        min-width: 100px;
        // max-width: 300px;
        overflow-x: scroll;
        .menu-button {
          margin-left: 0em;
          font-size: 0.8em;
          .homepage-top-menu-icons {
            display: none;
          }
        }
      }

      .homepage-bottom-page {
        .single-post {
         
          .post-info {
            padding: 0.5em 0.5em 0.5em 1em;

            .post-title {
              font-size: 0.7em;
             
            }
            .post-description {
              font-size: 0.7em;
             
            }
          }
  
          .post-likes-comments {
            margin-top: 0.6em;
            max-width: 5em;
            min-width: 5em;
            // border: 1px solid red;
            display: flex;
            .post-icons {
              margin: 0.5em 0em 0 0;
              color: white;
              font-size: 1em;
            }
            p {
              font-size: 0.8em;
            }
          }
        }
      }

    }
    .left-homepage {
      display: none;
    }
    .right-homepage {
      display: none;
    }
  }
}

@media (min-width: 451px) and (max-width: 1200px) {
  .main-page {
    // border: 1px solid red;
    .center-homepage {
      .homepage-top-menu {
        min-width: 30em;
        // max-width: 300px;
        overflow-x: scroll;
        .menu-button {
          // border: 1px solid red;
          margin-right: 1em;
          margin-left: 0em;
          font-size: 0.8em;
          .homepage-top-menu-icons {
            display: none;
          }
        }
      }
      .homepage-bottom-page {
        .single-post {
         
          .post-info {
            padding: 0.5em 0.5em 0.5em 1em;
            .post-username {
              font-size: 0.7em;
             
            }
  
            .post-title {
              font-size: 0.7em;
             
            }
            .post-description {
              font-size: 0.7em;
             
            }
          }
  
          .post-likes-comments {
            margin-top: 0.6em;
            max-width: 5em;
            min-width: 5em;
            // border: 1px solid red;
            display: flex;
            .post-icons {
              margin: 0.5em 0em 0 0;
              color: white;
              font-size: 1em;
            }
            p {
              font-size: 0.8em;
            }
          }
        }
      }
    }

    .left-homepage {
      display: none;
    }
  }
}

@media (min-width: 451px) and (max-width: 1315px) {
  .main-page {
    // border: 1px solid red;
    .center-homepage {
      .homepage-top-menu {
        min-width: 30em;
      }
    }
  }
}
