.user-dashboard {
  max-width: 100vw;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  // border: 1px solid red;

  .total-user-posts {
    max-width: 30em;
    // min-width: 30em;
    .single-post {
      color: white;
      margin: auto;
      display: flex;
      margin-top: 1%;
      border: 1px var(--border-color);
      border-left: 1px var(--border-color);

      &:hover {
        box-shadow: 0 4px 6px rgba(183, 178, 178, 0.1);
      }

      .post-info {
        //   border: 1px solid rgba(238, 237, 239, 0.631);
        padding: 1em 2em 1em 2em;
      }

      .website-preview {
        margin-left: auto;
        width: 10%;
      }

      .post-username {
        font-size: 0.7em;
        color: rgb(101, 101, 174);
        font-weight: 900;
      }

      .post-title {
        font-size: 0.9em;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
      }
      .post-description {
        font-size: 0.7em;
      }
    }
  }

  .user-profile {
    text-align: center;
    margin-left: 5%;
    max-width: 10em;
    padding: 2em;
    // min-width: 10em;
    background-color: white;
    box-shadow: 0px 1px 10px 1px rgb(120, 115, 115);
    background-color: var(--background-color);

    .user-profile-upper-container {
      margin-top: 10%;
      .image-container {
        background: white;
        display: inline-block;
        max-width: 5em;
        height: 5em;
        border-radius: 50%;
        // padding: 1%;

        img {
          max-width: 5em;
          border-radius: 50%;
          //   border: 5px solid rgb(245, 245, 237);
          height: 5em;
        }
      }
    }
    .user-profile-lower-container {
      color: white;
    }
  }
}

@media (max-width: 450px) {
  .user-dashboard {
    padding: 1em;
    flex-direction: column-reverse;
    .user-profile {
      margin-bottom: 1em;
      padding: 1em;
      max-width: 30em;
    }
  }
}

@media (min-width: 451px) and (max-width: 850px) {
  .user-dashboard {
    padding: 1em;
  }
}

