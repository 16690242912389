:root {
  --main-header-color: rgb(15, 171, 116);
  --paragraph-color: #fff;
  --navbar-bg: #1b1d22; /* Navy Blue */
  --body-bg: #22242a; /* Darker Navy Blue */
  --homepage-bg: #323741;
  --border-color: solid rgb(88, 88, 86);
  --icon-color: rgb(5, 100, 100);
  --comment-username-color: rgb(15, 121, 121);
  --user-post-color: gold;
  --gradient-background: linear-gradient(135deg, #056464 0%, #009696 100%);
  --gradient-background-hover: linear-gradient(135deg, #b6eb77 0%, #359ab6 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw;
  overflow-x: hidden;
  scrollbar-width: none;
  -webkit-scrollbar: none;
  -webkit-appearance: none;

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
}

body {
  background-color: var(--body-bg);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.3125rem;
  line-height: 1.6rem;
  margin-bottom: 10%;
  margin: auto;
  
}
